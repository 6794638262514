import React from 'react'

import PropTypes from 'prop-types'

import { millisecondsToHMS } from '../../utils/numbers'

class TimeCounter extends React.PureComponent {
  startCountingTime = 0

  componentDidMount() {
    this.startCountingTime = Date.now()
    this.startCounting()
  }

  componentWillUnmount() {
    this.stopCounting()
  }

  startCounting() {
    const startMilliseconds = this.props.start * 1000
    const timeDiff = Date.now() - this.startCountingTime
    this.setState({
      timeToShow: startMilliseconds + timeDiff,
    })

    if (this.props.stopOn0 && this.props.start === 0) {
      // don't start timer on stopOn0 and start === 0
      return
    }

    this.toId = setTimeout(this.startCounting.bind(this), 1000)
  }

  stopCounting() {
    clearTimeout(this.toId)
  }

  render() {
    const { timeToShow } = this.state || {}
    const { stopOn0, start, ...rest } = this.props // eslint-disable-line no-unused-vars

    return <span {...rest}>{millisecondsToHMS(timeToShow)}</span>
  }
}

TimeCounter.propTypes = {
  start: PropTypes.number, // in secondes
  stopOn0: PropTypes.bool, // in secondes
}

TimeCounter.defaultProps = {
  start: 0, // in secondes
  stopOn0: false,
}

export default TimeCounter
