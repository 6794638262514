import React from 'react'

import Translator from 'bazinga-translator'

import { THEME } from '../../containers/App/constants'
import Clock from '../Clock'
import { LoginTime } from '../LoginTime'
import { LawInfoWrapper } from './styles/LawInfoWrapper'

function LawInfo() {
  if (THEME.startsWith('betor')) {
    return (
      <LawInfoWrapper>
        <div>{Translator.trans('hazard_addiction_info')}</div>
        <Clock />
        <LoginTime />
      </LawInfoWrapper>
    )
  }

  return null
}

export default LawInfo
