import { push } from 'redux-first-history'

import { CONTENT_PREFIX } from '../containers/App/constants'
import { LOGIN_URL, REGISTER_CHOICE_ADDRESS } from '../containers/LoginPage/constants'
import { getConfiguredStore } from '../reduxStore'

/**
 * Get param value from query string by param name
 */
export function getSearchParamByName(name: string, url: string) {
  const nameToParse = name.replace(/[[\]]/g, '\\$&')
  const regex = new RegExp(`[?&]${nameToParse}(=([^&#]*)|&|#|$)`)
  const results = regex.exec(url)

  if (!results || !results[2]) {
    return ''
  }

  return decodeURIComponent(results[2].replace(/\+/g, ' '))
}

export function hrefToTo(href: string) {
  return href ? href.replace(/(app_dev\.php\/)/g, '').replace(window.location.origin, '') : '#'
}

export function isSelfUrl(url: string) {
  const isBaseUrl = url.indexOf(window.location.origin) === 0
  const isRelative = url.indexOf('/') === 0

  return isRelative || isBaseUrl
}

export function redirect(url: string) {
  const redirectNeeded = isRedirectNeeded(url)
  const isGame = url.indexOf('/game/') > 0 && url.indexOf('/play') > 0
  const re = /\.([a-zA-Z0-9]+)$/
  const isFile = !!re.exec(url)
  const isTheSame = url === window.location.pathname || url === window.location.href
  const { store } = getConfiguredStore()

  if (!isTheSame && !redirectNeeded && !isGame && !isFile && isSelfUrl(url) && store) {
    store.dispatch(push(hrefToTo(url)))
    return
  }

  window.location.href = url
}

export function isRedirectNeeded(url: string) {
  return (
    !!window.location.href.match(/(\/logout)$/) ||
    !!url?.match(/(\/game\/.*\/play\/?[0-9]?)$/) ||
    !!url?.match(/(\/logout)$/) ||
    !!url?.match(/(\/api\/collect\/oauth\/v2\/authorize)/) ||
    !!url?.match(/(\/oauth\/connect)/) ||
    !!url?.match(/(\/admin\/)/) ||
    !!url?.match(/\/payment\/transfer\/finish/) ||
    !!url?.match(/\/profile\/verification_method\/netera/) ||
    !!url?.match(/\/profile\/verification_method\/bank_id_cz/) ||
    !!url?.match(/\/netera\/register/)
  )
}

export function updateContentPath(path: string) {
  // remove double slashes except for http:// or https://
  const filledPathname = path
    .replace(/^\//, `/${CONTENT_PREFIX}`)
    .replace(/\/+/g, '/')
    .replace(/(https?:\/)([^/])/g, '$1/$2')
  const cacheParam = `t=${Date.now()}`
  const urlJoined = filledPathname.indexOf('?') > 0 ? '&' : '?'
  return `${filledPathname}${urlJoined}${cacheParam}`
}

export function getRegisterAddress() {
  return REGISTER_CHOICE_ADDRESS
}

export function getLoginUrlWithRedirect(redirectUrl: string) {
  return `${LOGIN_URL}?redirect=${redirectUrl}`
}
