/* eslint-disable default-param-last */
import { fromJS } from 'immutable'
import { uniqBy } from 'lodash'
import { createImmutable, Immutable } from 'types/Immutable'
import { MenuItem } from 'types/MenuItem'
import { User, UserType } from 'types/User'

import { Announcement } from '../../types/Announcement'
import { CmsPage } from '../../types/CmsPage'
import { handleUserInteraction, reinitializeMenu, toggleisOpenFlagByElementId } from '../GenericMenu/menuHandlers'
import { GlobalAction } from './actions'
import {
  INIT_LOAD,
  INIT_LOAD_SUCCESS,
  INIT_LOAD_REDIRECT,
  INIT_LOAD_ERROR,
  INIT_RELOAD_NEEDED,
  TOGGLE_MOBILE_MENU,
  TOGGLE_USER_MENU,
  TOGGLE_LANG_MENU,
  WINDOW_CLICKED,
  SEND_GAME_FINISH,
  SEND_GAME_FINISH_SUCCESS,
  SEND_GAME_FINISH_ERROR,
  INIT_RESET,
  TOGGLE_GENERIC_MENU_POSITION,
  TOGGLE_GENERIC_MENU_SECTION,
  CLOSE_ANNOUNCEMENT,
  COOKIE_CONSENTS_CHANGE,
  TOGGLE_GAME_FAVORITE,
} from './constants'

export const initialGlobalState = createImmutable({
  firstLoad: true,
  loading: true,
  error: false as boolean | any,
  chunkLoading: false,
  reloadNeeded: false,
  reloading: false,
  serverDate: '',
  branch: '',
  user: {
    type: UserType.unknown,
    email: 'DEMO',
    username: 'DEMO',
    fullname: 'DEMO',
    id: '',
    forFunRegistrationReq: false,
    isRealPlayPermitted: false,
    isPlayForFunPermitted: true,
    noGamingSession: false,
    isLoginAndPlayPossible: true,
    gameFinishUrl: null,
    reInitialize: null,
    isLogged: undefined,
  } as unknown as User,
  announcements: [] as Announcement[],
  cmsPage: {
    error: false,
    pageData: {
      article: '',
      background: [],
      gallery: [],
      metaHeaders: {
        description: '',
        robots: '',
      },
      relCanonical: '',
      relatedPages: [],
      siteTitle: '',
      template: '',
      title: '',
    },
    mobileFilterOpen: false,
  } as CmsPage,
  mainMenu: {
    items: [] as MenuItem[],
    userMenuOpen: false,
  },
  footerMenu: {
    items: [] as MenuItem[],
  },
  footerBlock: '',
  secondaryMenu: {
    items: [] as MenuItem[],
    open: false,
  },
  mobileMenu: {
    items: [] as MenuItem[],
    open: false,
  },
  langMenu: {
    items: [] as MenuItem[],
    open: false,
  },
  cookie: 0,
})

function mergeAnnouncements(currentAnnouncements: Immutable<Announcement[]>, newAnnouncements: Announcement[]) {
  return fromJS(uniqBy([...currentAnnouncements.toJS(), ...newAnnouncements], 'id'))
}

function getUserType(user: User) {
  if (user.isLogged) {
    if (user.isStaff) {
      return UserType.staff
    }

    return UserType.player
  }

  return UserType.anonymous
}

function appReducer(state = initialGlobalState, action: GlobalAction) {
  switch (action.type) {
    case INIT_RESET:
      return initialGlobalState

    case INIT_LOAD:
      return state
        .set('loading', true)
        .set('error', false)
        .set('reloading', !!state.get('reloadNeeded'))
        .set('reloadNeeded', false)

    case INIT_LOAD_SUCCESS: {
      const {
        announcements,
        branch,
        cmsPage,
        footerBlock,
        footerMenu,
        langMenu,
        mainMenu,
        mobileMenu,
        secondaryMenu,
        serverDate,
        user,
      } = action.data
      const userType = getUserType(user)

      return state.merge(
        fromJS({
          firstLoad: false,
          loading: false,
          reloading: false,
          serverDate,
          footerBlock,
          user: { ...user, isLogged: !!user.isLogged, type: userType },
          announcements: mergeAnnouncements(state.get('announcements'), announcements),
          branch,
          mainMenu: {
            items: mainMenu,
            userMenuOpen: state.getIn(['mainMenu', 'userMenuOpen']),
          },
          footerMenu: {
            items: footerMenu,
          },
          secondaryMenu: {
            items: secondaryMenu,
            open: false,
          },
          mobileMenu: {
            items: reinitializeMenu(fromJS(mobileMenu)),
            open: false,
          },
          langMenu: {
            items: langMenu,
            open: false,
          },
          cmsPage: {
            pageData: {
              ...cmsPage.pageData,
              relatedPages: reinitializeMenu(fromJS(cmsPage.pageData.relatedPages)),
            },
            error: false,
            mobileFilterOpen: false,
          },
        }),
      )
    }

    case INIT_LOAD_REDIRECT:
      return state.set('firstLoad', false)

    case INIT_LOAD_ERROR:
      return state.set('error', action.error).set('loading', false).set('reloading', false)

    case WINDOW_CLICKED:
      return state
        .setIn(['secondaryMenu', 'open'], false)
        .setIn(['langMenu', 'open'], false)
        .setIn(['mainMenu', 'userMenuOpen'], false)

    case TOGGLE_LANG_MENU: {
      const currentOpenState = state.getIn(['langMenu', 'open'])
      return state.setIn(['langMenu', 'open'], !currentOpenState)
    }

    case TOGGLE_USER_MENU: {
      const currentOpenState = state.getIn(['mainMenu', 'userMenuOpen'])
      return state.setIn(['mainMenu', 'userMenuOpen'], !currentOpenState)
    }

    case CLOSE_ANNOUNCEMENT: {
      return state.set(
        'announcements',
        state.get('announcements').filter((announcement) => announcement.get('id') !== action.id),
      )
    }

    case SEND_GAME_FINISH: {
      return state.setIn(['user', 'sendingGameFinish'], true)
    }

    case SEND_GAME_FINISH_SUCCESS: {
      return state.setIn(['user', 'sendingGameFinish'], false)
    }

    case SEND_GAME_FINISH_ERROR: {
      return state.setIn(['user', 'sendingGameFinish'], false)
    }

    case INIT_RELOAD_NEEDED: {
      return state.set('reloadNeeded', true)
    }

    case TOGGLE_MOBILE_MENU: {
      const currentOpenState = state.getIn(['mobileMenu', 'open'])
      return state.setIn(['mobileMenu', 'open'], !currentOpenState)
    }

    case TOGGLE_GENERIC_MENU_POSITION: {
      const { id, menuType } = action

      if (menuType === 'desktop') {
        return state.setIn(
          ['cmsPage', 'pageData', 'relatedPages'],
          handleUserInteraction(state.getIn(['cmsPage', 'pageData', 'relatedPages']), id),
        )
      }

      return state.setIn(['mobileMenu', 'items'], handleUserInteraction(state.getIn(['mobileMenu', 'items']), id))
    }

    case TOGGLE_GENERIC_MENU_SECTION: {
      const { id, menuType } = action

      if (menuType === 'desktop') {
        return state.setIn(
          ['cmsPage', 'pageData', 'relatedPages'],
          toggleisOpenFlagByElementId(state.getIn(['cmsPage', 'pageData', 'relatedPages']), id),
        )
      }

      return state.setIn(['mobileMenu', 'items'], toggleisOpenFlagByElementId(state.getIn(['mobileMenu', 'items']), id))
    }

    case TOGGLE_GAME_FAVORITE: {
      const { slug } = action

      const currentFavorites = state.getIn(['user', 'favoriteGames']).toJS()
      const isInFav = currentFavorites.some((oldSlug) => oldSlug === slug)

      if (isInFav) {
        const newFavorites = currentFavorites.filter((oldSlug) => oldSlug !== slug)

        return state.setIn(['user', 'favoriteGames'], fromJS(newFavorites))
      }

      return state.setIn(['user', 'favoriteGames'], fromJS([...currentFavorites, slug]))
    }

    case COOKIE_CONSENTS_CHANGE: {
      return state.set('cookie', Date.now())
    }

    default: {
      return state
    }
  }
}

export default appReducer
