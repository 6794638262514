import React from 'react'

import PropTypes from 'prop-types'
import styled from 'styled-components'

import { bh, transition, animationBouncingEase, fontWeightLight, secondaryHeaderColor } from '../../utils/style-utils'
import IconLink from '../IconLink'

const StyledA = styled(IconLink)`
  display: flex;
  color: ${secondaryHeaderColor};
  text-transform: uppercase;

  > span {
    transform: none;
    margin-top: 0;
    font-weight: ${fontWeightLight};
  }

  > svg {
    cursor: pointer;
    fill: ${secondaryHeaderColor};
    margin: 0 -${bh * 1.5}px;
    transform: scale(0.4);

    ${transition('fill, transform', 0.2, animationBouncingEase)};

    + span {
      display: none;
    }
  }

  &.active {
    text-decoration: underline;

    pointer-events: none;
    cursor: default;
  }

  &:hover {
    color: ${secondaryHeaderColor};
    text-decoration: underline;

    > span {
      transform: none;
    }

    > svg {
      fill: ${secondaryHeaderColor};
      transform: scale(0.5);
    }
  }
`

function A({ item }) {
  return (
    <StyledA
      link={item.get('uri')}
      label={item.get('label')}
      icon={item.get('icon')}
      rel={item.get('rel')}
    />
  )
}

A.propTypes = {
  item: PropTypes.node,
}

export default A
