import { fromJS } from 'immutable'

export type Immutable<T> = T extends string | number | boolean
  ? T
  : T extends any[]
    ? ImmutableArray<T[number]>
    : ImmutableMap<T>

type ImmutableArray<T> = {
  map(mapperFn: (val: Immutable<T>) => void): any
  filter(mapperFn: (val: Immutable<T>) => void): ImmutableArray<T>
  sort(mapperFn: (a: Immutable<T>, b: Immutable<T>) => void): ImmutableArray<T>
  get(name: number): Immutable<T>
  toJS(): T[]
  size: number
}

type ImmutableMap<T> = {
  get<K extends keyof T>(name: K): Immutable<T[K]>

  getIn<K1 extends keyof T>(name: [K1]): Immutable<T[K1]>
  getIn<K1 extends keyof T, K2 extends keyof T[K1]>(name: [K1, K2]): Immutable<T[K1][K2]>
  getIn<K1 extends keyof T, K2 extends keyof T[K1], K3 extends keyof T[K1][K2]>(
    name: [K1, K2, K3],
  ): Immutable<T[K1][K2][K3]>
  getIn<K1 extends keyof T, K2 extends keyof T[K1], K3 extends keyof T[K1][K2], K4 extends keyof T[K1][K2][K3]>(
    name: [K1, K2, K3, K4],
  ): Immutable<T[K1][K2][K3][K4]>
  getIn<
    K1 extends keyof T,
    K2 extends keyof T[K1],
    K3 extends keyof T[K1][K2],
    K4 extends keyof T[K1][K2][K3],
    K5 extends keyof T[K1][K2][K3][K4],
  >(
    name: [K1, K2, K3, K4, K5],
  ): Immutable<T[K1][K2][K3][K4][K5]>

  set<K extends keyof T, V = T[K]>(key: K, value: NoInfer<Immutable<V>>): Immutable<T>

  setIn<K1 extends keyof T, V = T[K1]>(name: [K1], value: NoInfer<Immutable<V>>): Immutable<T>
  setIn<K1 extends keyof T, K2 extends keyof T[K1], V = T[K1][K2]>(
    name: [K1, K2],
    value: NoInfer<Immutable<V>>,
  ): Immutable<T>
  setIn<K1 extends keyof T, K2 extends keyof T[K1], K3 extends keyof T[K1][K2], V = T[K1][K2][K3]>(
    name: [K1, K2, K3],
    value: NoInfer<Immutable<V>>,
  ): Immutable<T>
  setIn<
    K1 extends keyof T,
    K2 extends keyof T[K1],
    K3 extends keyof T[K1][K2],
    K4 extends keyof T[K1][K2][K3],
    V = T[K1][K2][K3][K4],
  >(
    name: [K1, K2, K3, K4],
    value: NoInfer<Immutable<V>>,
  ): Immutable<T>
  setIn<
    K1 extends keyof T,
    K2 extends keyof T[K1],
    K3 extends keyof T[K1][K2],
    K4 extends keyof T[K1][K2][K3],
    K5 extends keyof T[K1][K2][K3][K4],
    V = T[K1][K2][K3][K4][K5],
  >(
    name: [K1, K2, K3, K4, K5],
    value: NoInfer<Immutable<V>>,
  ): Immutable<T>

  merge(obj: Immutable<Partial<T>>): Immutable<T>
  toJS(): T
}

export function createImmutable<T>(o: T) {
  return fromJS(o) as Immutable<T>
}
