import React from 'react'

import PropTypes from 'prop-types'
import styled from 'styled-components'

import { secondaryHeaderBg, media } from '../../utils/style-utils'

const Div = styled.div`
  padding: 0 10px;

  ${media.md`
    order: 4;
    width: 100%;
    background: ${secondaryHeaderBg};
  `}
`

function Li({ branch }) {
  if (!branch) {
    return null
  }

  return <Div>@{branch}</Div>
}

Li.propTypes = {
  branch: PropTypes.string,
}

export default Li
