import React from 'react'

import { useIsNewLayout } from 'containers/App/NewLayout/useIsNewLayout'

import { GameDialogWithData } from './GameDialogWithData'
import useGameDialog from './hooks/useGameDialog'

export function GameDialogBox() {
  const { gameDialog, changeGameDialog } = useGameDialog()
  const isNewLayout = useIsNewLayout()

  if (!gameDialog || isNewLayout) {
    return null
  }

  return (
    <GameDialogWithData
      slug={gameDialog}
      onClose={() => changeGameDialog('')}
    />
  )
}
