// import { LANG } from '../App/constants'

export const PAYMENT_METHODS_STATE_KEY = 'paymentMethods'
export const FORM_NEW_BANK_ACCOUNT_NAME = 'formNewBankAccount'
export const FORM_NEW_CREDIT_CARD_NAME = 'formNewCreditCard'
export const FORM_NEW_COLLECT_ACCOUNT_NAME = 'formNewCollectAccount'
export const FORM_NEW_NETERA_ACCOUNT_NAME = 'formNewNeteraAccount'
export const FORM_NEW_GOOGLE_PAY_ACCOUNT_NAME = 'formNewGooglePay'
export const FORM_NEW_APPLE_PAY_ACCOUNT_NAME = 'formNewApplePay'
export const FORM_VERIFY_ITEM_NAME = 'formVerifyItem'
export const FORM_SUBSEQUENT_DEPOSIT_NAME = 'formSubsequentDeposit'
export const FORM_VOUCHER_NAME = 'voucherdepositcode'

export const CONTENT_LOAD = 'Casino/PaymentMethod/LOAD'
export const CONTENT_LOAD_SUCCESS = 'Casino/PaymentMethod/LOAD_SUCCESS'
export const CONTENT_LOAD_ERROR = 'Casino/PaymentMethod/LOAD_ERROR'

export const DELETE_PAYMENT_METHOD_START = 'Casino/PaymentMethod/delete/start'
export const DELETE_PAYMENT_METHOD_SUCCESS = 'Casino/PaymentMethod/delete/success'
export const DELETE_PAYMENT_METHOD_ERROR = 'Casino/PaymentMethod/delete/error'

export const SET_DEFAULT_PAYMENT_METHOD_START = 'Casino/PaymentMethod/setDefault/start'
export const SET_DEFAULT_PAYMENT_METHOD_SUCCESS = 'Casino/PaymentMethod/setDefault/success'
export const SET_DEFAULT_PAYMENT_METHOD_ERROR = 'Casino/PaymentMethod/setDefault/error'

export const TOGGLE_NEW_CREDIT_CARD_FORM = 'Casino/PaymentMethod/toggleNewCardForm'
export const TOGGLE_NEW_BANK_ACCOUNT_FORM = 'Casino/PaymentMethod/toggleNewBankAccountForm'
export const TOGGLE_FORM = 'Casino/PaymentMethod/TOGGLE_FORM'
export const HIDE_FORM = 'Casino/PaymentMethod/HIDE_FORM'
export const SHOW_FORM = 'Casino/PaymentMethod/SHOW_FORM'

export const SELECT_BONUS = 'Casino/PaymentMethod/selectBonus'
export const SELECT_PAYMENT_METHOD = 'Casino/PaymentMethod/selectPaymentMethod'

export const BANK_ACCOUNT_ADDED = 'Casino/PaymentMethod/bankAccountAdded'
export const CREDIT_CARD_ADDED = 'Casino/PaymentMethod/creditCardAdded'
export const ITEM_VERIFIED = 'Casino/PaymentMethod/itemVerified'
export const WITHDRAW_SUCCESS = 'Casino/PaymentMethod/WITHDRAW_SUCCESS'

export const CREDIT_CARD_VISA = 'Visa'
export const CREDIT_CARD_MASTERCARD = 'MasterCard'

export const GATEWAY_COLLECT = 'collect'
export const GATEWAY_NETERA = 'netera'
export const GATEWAY_GOOGLE_PAY = 'googlePay'
export const GATEWAY_APPLE_PAY = 'applePay'

export const BANK_ACCOUNT_TYPE = 0
export const CREDIT_CARD_TYPE = 1
export const GATEWAY_ACCOUNT_TYPE = 2
export const NETERA_ACCOUNT_TYPE = 3
export const GOOGLE_PAY_ACCOUNT_TYPE = 4
export const APPLE_PAY_ACCOUNT_TYPE = 5

export const TYPES_TEXT = ['bank-account', 'credit-card', 'collect', 'netera']
