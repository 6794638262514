import { BASE_PATH, LANG } from '../App/constants'

export const RESET_PAGE_STATE = 'RESET_PAGE_STATE' as const
export const INIT_LOAD_URI = `${BASE_PATH}/content/${LANG}/init`

export const INIT_LOAD = 'Casino/MainLayout/INIT_LOAD' as const
export const INIT_RELOAD_NEEDED = 'Casino/MainLayout/INIT_RELOAD_NEEDED' as const
export const INIT_LOAD_SUCCESS = 'Casino/MainLayout/INIT_LOAD_SUCCESS' as const
export const INIT_LOAD_REDIRECT = 'Casino/MainLayout/INIT_LOAD_REDIRECT' as const
export const INIT_LOAD_ERROR = 'Casino/MainLayout/INIT_LOAD_ERROR' as const
export const INIT_RESET = 'Casino/MainLayout/INIT_RESET' as const

export const ADD_FLASH_MESSAGE = 'Casino/MainLayout/ADD_FLASH_MESSAGE' as const
export const CLOSE_FLASH_MESSAGE = 'Casino/MainLayout/CLOSE_FLASH_MESSAGE' as const
export const CLOSE_ALL_FLASH_MESSAGES = 'Casino/MainLayout/CLOSE_ALL_FLASH_MESSAGES' as const

export const CLOSE_ANNOUNCEMENT = 'Casino/MainLayout/CLOSE_ANNOUNCEMENT' as const

export const WINDOW_CLICKED = 'Casino/MainLayout/WINDOW_CLICKED' as const

export const TOGGLE_MOBILE_MENU = 'Casino/MainLayout/TOGGLE_MOBILE_MENU' as const
export const TOGGLE_GENERIC_MENU_SECTION = 'Casino/MainLayout/TOGGLE_GENERIC_MENU_SECTION' as const
export const TOGGLE_GENERIC_MENU_POSITION = 'Casino/MainLayout/TOGGLE_GENERIC_MENU_POSITION' as const

export const TOGGLE_LANG_MENU = 'Casino/MainLayout/TOGGLE_LANG_MENU' as const

export const SEND_GAME_FINISH = 'Casino/MainLayout/SEND_GAME_FINISH' as const
export const SEND_GAME_FINISH_SUCCESS = 'Casino/MainLayout/SEND_GAME_FINISH_SUCCESS' as const
export const SEND_GAME_FINISH_ERROR = 'Casino/MainLayout/SEND_GAME_FINISH_ERROR' as const

export const TOGGLE_USER_MENU = 'Casino/MainLayout/TOGGLE_USER_MENU' as const
export const INSERT_CAPTCHA = 'Casino/MainLayout/INSERT_CAPTCHA' as const

export const COOKIE_CONSENTS_CHANGE = 'Casino/MainLayout/COOKIE_CONSENTS_CHANGE' as const

export const TOGGLE_GAME_FAVORITE = 'Casino/User/ToggleGameFavorite' as const
