import React from 'react'

import Translator from 'bazinga-translator'
import TimeCounter from 'components/TimeCounter'
import { useUser } from 'containers/MainLayout/selectors'

export function LoginTime() {
  const user = useUser()
  const limitTime = user?.limits?.loginTime

  if (!user.isLogged || !limitTime) {
    return null
  }

  return (
    <div>
      {Translator.trans('loginTime', {}, 'users')}: <TimeCounter start={limitTime} />
    </div>
  )
}
