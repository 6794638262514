import React from 'react'

import { LoginTime } from 'components/LoginTime'
import PropTypes from 'prop-types'

import Container from '../Container'
import LanguageSwitcher from '../LanguageSwitcher'
import List from '../List'
import Branch from './Branch'
import Li from './Li'
import Ul from './Ul'
import Wrapper from './Wrapper'

function SecondaryHeader({ menu, secondaryMenuOpen, langMenu, langMenuOpen, langMenuToggler, branch }) {
  return (
    <Wrapper
      open={secondaryMenuOpen}
      onClick={(e) => {
        e.stopPropagation()
      }}
    >
      <Container>
        <Branch branch={branch} />
        <LoginTime />
        <LanguageSwitcher
          langMenu={langMenu}
          open={langMenuOpen}
          clickHandler={langMenuToggler}
        />
        <List
          items={menu}
          Ul={Ul}
          Li={Li}
        />
      </Container>
    </Wrapper>
  )
}

SecondaryHeader.propTypes = {
  menu: PropTypes.object,
  secondaryMenuOpen: PropTypes.bool,
  langMenu: PropTypes.object,
  langMenuOpen: PropTypes.bool,
  branch: PropTypes.string,
  langMenuToggler: PropTypes.func,
}

export default SecondaryHeader
